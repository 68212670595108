@charset 'utf-8';

.group-select-set {
  
  &.select-from-list {
    
    .custom-select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }    
  }
}

.group-select-add {
  
  .input-group.select-from-list {

    .custom-select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
  
  .input-group.add-to-list {
    display: none;

    input {
      display: none;
      
      &.form-control-plaintext {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
      }
    }
  }
            
  .input-group-append {
    display: -ms-flexbox;
    display: flex;
    margin-left: -1px;

    .btn {
      box-sizing: border-box;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      position: relative;
      z-index: 2;
      cursor: pointer;
      padding: .375rem .75rem;
      font-size: 1rem;
      line-height: 1.5;
      webkit-appearance: button;

      &.in {
        border-radius: 0;
      }

      &.btn-outline-secondary.btn-to-list {
        color: color(f-blue);
        border-color: #ced4da;

        &:hover {
          color: white;
        }
        
        &:disabled {
          color: color(f-red-trans);
          
          &:hover {
            color: color(white-light);
            background-color: color(inactive);
          }
        }
      }

      &.btn-outline-secondary.btn-remove-list {
        color: color(f-red);
        border-color: #ced4da;
        display: none;

        &:hover {
          color: white;
        }
      }

      &.btn-outline-secondary.btn-edit-list {
        color: color(f-green);
        border-color: #ced4da;
        display: none;

        &:hover {
          color: white;
        }
      }

      &.btn-outline-secondary.btn-action-list {
        color: color(f-green);
        border-color: #ced4da;

        &:hover {
          color: white;
        }
      }

      &.btn-outline-secondary.btn-cancel-to-list {
        border-color: #ced4da;
      }
    }
  }             
}