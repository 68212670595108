@charset 'utf-8';

/// COLORS
/// how this map use with function "color"
/// .element-html {
///   background-color: color(img-bg); // rgb(255, 255, 255)
/// }
$colors: (
  base: rgb(80, 90, 100),
  text: rgb(80, 90, 100),
  odd: rgb(250, 250, 250),
  even: rgb(240, 240, 240),
  primary: rgb(74, 164, 234),
  cancel: rgb(190, 200, 210),
  f-blue: rgb(74, 164, 234),
  f-blue-dark: rgb(24, 114, 184),
  f-green: rgb(54, 204, 104),
  f-green-dark: rgb(4, 154, 54),
  f-red: rgb(194, 34, 44),
  f-gold: rgb(200,100,50),
  f-blue-trans: rgba(74, 164, 234, 0.5),
  f-green-trans: rgba(54, 204, 104, 0.5),
  f-red-trans: rgba(194, 34, 44, 0.5),
  f-red-dark-trans: rgba(194, 34, 44, 0.75),
  f-gold-trans: rgba(200,100,50,0.75),
  red-dark: rgb(194, 34, 44),
  f-green-trans-small: rgba(14, 94, 34, 0.5),
  f-red-trans-small: rgba(194, 34, 44, 0.5),
  f-orange: rgb(239, 129, 37),
  f-yellow: rgb(255, 229, 37),
  f-white: rgb(255,255,255),
  f-dark-green: rgb(4, 34, 14),
  row-search: rgb(74, 164, 234),
  row-search-border: rgba(74, 164, 234, 0.1),
  body: rgb(255, 255, 255),
  body-light: rgba(250, 255, 253, 0.3),
  blue-light: rgba(250, 253, 255, 0.55),
  white-light: rgba(255, 255, 255, 0.7),
  white-very-light: rgba(255, 255, 255, 0.4),
  dark: rgb(80, 90, 100),
  dark-shadow: rgba(80, 90, 100, 0.5),
  dark-hover: rgba(74, 164, 234, 0.5),
  modal-bg: rgb(245,247,242),
  modal-border: rgb(183,186,183),
  modal-bg-inactive: rgb(224,225,224),
  modal-shadow: rgba(0,0,0,0.05),
  light: rgb(230, 240, 250),
  nav-bg: rgba(246, 250, 254, 0.85),
  img-bg: rgba(255, 255, 255, 0.9),
  img-border: rgb(222, 226, 230), /*#DEE2E6*/
  accent: rgb(194, 34, 44),
  item-nav: rgba(255, 255, 255, 0.9),
  message-white: rgb(255, 255, 255),
  active: rgb(54, 204, 104),
  active-light: rgba(8, 232, 10, 0.1),
  inactive: rgb(140,160,150),
  link: rgb(54, 124, 194),
  title: rgb(90, 100, 110),
  title-nav: rgb(54, 124, 194),
  title-mobile: rgb(194, 34, 44), 
  title-1: rgb(240, 250, 255), /*rgb(74, 164, 234)*/
  title-2: rgb(50, 200, 100),
  title-3: rgb(50, 190, 110),
  title-4: rgb(50, 180, 120),
  title-5: rgb(50, 170, 130),
  title-6: rgb(50, 160, 140),
  header: rgba(255, 255, 255, 0.9),
  footer: rgb(233, 249, 255),
  footer-bg: rgba(240, 245, 255, 0.90),
  footer-link: rgb(160, 180, 215),
  none: transparent,
  f-none: transparent
);

/// Pobranie koloru z mapy kolorów (przykład pod mapą)
/// @access public
/// @param {String} $key - klucz koloru z mapy
@function color($key) {
  @if map-has-key($colors, $key) {
    @return map-get($colors, $key);
  }
  @warn "Unknown `#{$key}` in $colors.";
  @return null;
}

/// Stopniowo rozjaśniaj kolor
/// @access public
/// @param {String} $color-key - klucz koloru z mapy do rozjaśnienia
/// @param {Number} $percentage - procent pierwotnego `$color` w zwróconej wartości
/// @return {Color}
@function tint($color-key, $percentage) {
  @if map-has-key($colors, $color-key) {
    @return mix(white, map-get($colors, $color-key), $percentage);
  }
  @warn "Unknown `#{color-key}` in $colors.";
  @return null;
}

/// Stopniowo przyciemniaj kolor
/// @access public
/// @param {String} $color-key - klucz kolor z mapy do przyciemnienia
/// @param {Number} $percentage - procent pierwotnego `$color` w zwróconej wartości
/// @return {Color}
@function shade($color-key, $percentage) {
  @if map-has-key($colors, $color-key) {
    @return mix(black, map-get($colors, $color-key), $percentage);
  }
  @warn "Unknown `#{$color-key}` in $colors.";
  @return null;
}

