@charset 'utf-8';

#finder-block {
    background-color: transparent;
}
// search
#searchForm {
	background-color: transparent;

	.row-search {
		margin-top: 10px;
		margin-bottom: 0;

		@include respond-to(small) {
			margin-top: 0;
			margin-bottom: 0;
		}

		@include respond-to(medium) {
			margin-top: 0;
			margin-bottom: 0;
		}

		@include respond-to(large) {
			margin-top: 0;
			margin-bottom: 0;
		}

		@include respond-to(big) {
			margin-top: 0;
			margin-bottom: 0;
		}

		.input-group {
			margin-top: 0;
			margin-bottom: 0;
			font-size: 0.9em;

			>.input-group-prepend {
				width: 40px;

				>label,
				>span {
					display: inline-block;
					text-align: center;
					width: 100%;
					border-color: color(f-blue);
				}
			}

			/******* strzałki w polu numerycznym *******/
			/* Chrome, Safari, Edge, Opera */
			input.form-control::-webkit-outer-spin-button,
			input.form-control::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
			/* Firefox, IE, CSS3 */
			input.form-control[type="number"]{
				-webkit-appearance: none; /* Webkit */
				-moz-appearance: textfield; /* Mozilla */
				-o-appearance: none; /* Opera */
				-ms-appearance: none; /* Internet Explorer */
				appearance: none; /* CSS3 */

				font-size: 0.9rem;
				border-right: none;
			}

			input.form-control[type="text"],
			input.form-control[type="search"] {
				font-size: 0.9rem;
				border-right-width: 0;

				&.amount-range {
					color: color(row-search);
					text-align: center;
					cursor: help;
				}

				&#amount-max {
					border-right-width: 0;
				}
			}

			#slider-range {
				background-color: color(white-very-light);
				border: none;
				border-radius: 6px;

				div {
					background-color: color(f-blue-trans);
					border: 1px solid color(f-blue);
				}

				span {
					border-radius: 50%;
					/*border: 1px solid shade(f-blue, 30);*/
					border: none;
					width: 18px;
					height: 18px;
					line-height: 5px;
					background-color: color(f-blue);
				}
			}

			.input-group-text {
				font-size: 0.9rem;
				color: color(row-search);
				border-color: shade(row-search-border, 5);
				background-color: tint(row-search, 70);

				&.search-btn {
					background-color: tint(row-search, 95);
					text-shadow: 1px 1px 2px shade(f-blue, 10);

					&:hover {
						color: shade(f-blue, 30);
						background-color: tint(row-search, 85);
					}
				}

				&.clear-fields,
				&.clear-search,
				&.clear-amounts {
					padding-left: 3px;
					padding-right: 6px;
					background-color: white;
					border-left: none;
					cursor: pointer;
					font-weight: bold;
					color: color(f-red);

					&:hover {
						color: shade(f-red, 70);
					}
				}

				&.currency-label {
					padding-left: 1px;
					padding-right: 1px;
					text-align: center;
					border-radius: 0.25rem 0 0 0.25rem;
				}
			}

			.form-control {
				font-size: 0.9rem;

				color: shade(row-search, 30);
				border-color: shade(row-search-border, 5);

				&::placeholder {
					color: tint(row-search, 30);
				}

				&:focus {
					box-shadow: shadow(search-box);
				}
			}

			input{

				&.form-control[name="phrase"]{

					@include respond-to(big) {
						padding-left: 0.25rem;
						margin-left: -10px;
					}
				}

				&.form-control[name="amount-max"]{

					@include respond-to(big) {
						padding-left: 0.25rem;
						margin-left: -10px;
						border-right: none;
					}
				}
			}

			.custom-select {
				font-size: 0.9rem;
				line-height: 1.75;
				color: shade(row-search, 10);
				background-image: none;
				border-color: shade(row-search-border, 5);   
				-webkit-appearance: none; /* Webkit */
				-moz-appearance: none; /* Mozilla */
				-o-appearance: none; /* Opera */
				-ms-appearance: none; /* Internet Explorer */
				appearance: none; /* CSS3 */

				&:focus {
					box-shadow: shadow(search-box);
				}

				option {

					&:hover {
						color: tint(row-search, 90);
						background-color: color(row-search);
					}

					&.placeholder {
						color: tint(row-search, 50);
					}
				}
			}

			.custom-select[name="region_id"]{

				@include respond-to(big) {
					padding-left: 0.2rem;
					padding-right: 0.2rem;
					margin-left: -10px;
					margin-right: -10px;
				}
			}

			#slider-range {
				width: 100%;
			}
		}
	}
	
	#searchFilter {
		
		.form-group {
			
			select {
				font-size: 0.9rem;
				line-height: 1.75;
				color: shade(row-search, 10);
				background-image: none;
				border: 1px solid shade(row-search-border, 5);
				border-right: none;

				background-color: color(f-white);
				-webkit-appearance: none; /* Webkit */
				-moz-appearance: none; /* Mozilla */
				-o-appearance: none; /* Opera */
				-ms-appearance: none; /* Internet Explorer */
				appearance: none; /* CSS3 */

				&:focus {
					box-shadow: shadow(search-box);
				}

				option {

					&:hover {
						color: tint(row-search, 90);
						background-color: color(row-search);
					}

					&.placeholder {
						color: tint(row-search, 50);
					}
				}
			}

			&:first-of-type {
				
				select {
					border-bottom-left-radius: 5px;
					border-top-left-radius: 5px;
				}
			}
			
			&:last-of-type {
				margin-right: 7px;
				
				select {
					border-right: 1px solid shade(row-search-border, 5);
					border-bottom-right-radius: 5px;
					border-top-right-radius: 5px;
				}
			}
		}
	}
	
	.form-group {
		
		.btn.search-btn {
			font-size: 0.9rem;
			color: color(row-search);
			border-color: shade(row-search-border, 5);
			background-color: tint(row-search, 95);
			text-shadow: 1px 1px 2px shade(f-blue, 10);

			&:hover {
				color: shade(f-blue, 30);
				background-color: tint(row-search, 85);
			}
		}
	}
}