@charset 'utf-8';

/// SHADOWS MAP
/// how this map use with function "shadow"
/// .element-html {
///   box-shadow: shadow(img-bg); // rgb(255, 255, 255)
/// }
$shadows: (
  text: 0 3px 3px rgba(255, 255, 255, 0.5),
  text-dark: 0 1px 1px rgba(0, 10, 5, 0.25),
  text-h-mob: 0 0.1vw 0.1vw rgba(0, 5, 10, 0.1),
  box: 0 1px 2px 0 rgba(0, 10, 5, 0.02),
  search-box: 0 0 0 0.2rem rgba(54, 204, 104, 0.25),
);

/// Pobranie cienia z mapy cieni (przykład pod mapą kolorów)
/// @access public
/// @param {Number} $key - klucz cienia z mapy
@function shadow($key) {
  @if map-has-key($shadows, $key) {
    @return map-get($shadows, $key);
  }
  @warn "Unknown `#{$key}` in $shadows.";
  @return null;
}

