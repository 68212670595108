@charset 'utf-8';

/////////// FONTS ///////////
@font-face {
  font-family: 'NasalizationRg-Regular';
  font-style: normal;
  font-weight: 400; /*regular*/
  src: url('Typodermic/NasalizationRg-Regular.eot');
  src: 
    url('Typodermic/NasalizationRg-Regular.eot?#iefix') format('embedded-opentype'), 
    url('Typodermic/NasalizationRg-Regular.woff2') format('woff2'), 
    url('Typodermic/NasalizationRg-Regular.woff') format('woff'), 
    url('Typodermic/NasalizationRg-Regular.ttf') format('truetype')
  ;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300; /*light*/
  src: local('OpenSans Light'), local('OpenSans-Light'), url('Open_Sans/OpenSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300; /*light*/
  src: local('OpenSans LightItalic'), local('OpenSans-LightItalic'), url('Open_Sans/OpenSans-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400; /*regular*/
  src: local('OpenSans Regular'), local('OpenSans-Regular'), url('Open_Sans/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400; /*regular*/
  src: local('OpenSans Italic'), local('OpenSans-Italic'), url('Open_Sans/OpenSans-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600; /*semi-bold*/
  src: local('OpenSans SemiBold'), local('OpenSans-SemiBold'), url('Open_Sans/OpenSans-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600; /*semi-bold*/
  src: local('OpenSans SemiBoldItalic'), local('OpenSans-SemiBoldItalic'), url('Open_Sans/OpenSans-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700; /*bold*/
  src: local('OpenSans Bold'), local('OpenSans-Bold'), url('Open_Sans/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700; /*bold*/
  src: local('OpenSans BoldItalic'), local('OpenSans-BoldItalic'), url('Open_Sans/OpenSans-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800; /*extra-bold*/
  src: local('OpenSans ExtraBold'), local('OpenSans-ExtraBold'), url('Open_Sans/OpenSans-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800; /*extra-bold*/
  src: local('OpenSans ExtraBoldItalic'), local('OpenSans-ExtraBoldItalic'), url('Open_Sans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
}
/*LATO*/
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100; /*thin*/
  src: local('Lato Hairline'), local('Lato-Hairline'), url('Lato/Lato-Hairline.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100; /*thin*/
  src: local('Lato HairlineItalic'), local('Lato-HairlineItalic'), url('Lato/Lato-HairlineItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300; /*light*/
  src: local('Lato Light'), local('Lato-Light'), url('Lato/Lato-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300; /*light*/
  src: local('Lato LightItalic'), local('Lato-LightItalic'), url('Lato/Lato-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400; /*regular*/
  src: local('Lato Regular'), local('Lato-Regular'), url('Lato/Lato-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400; /*regular*/
  src: local('Lato Italic'), local('Lato-Italic'), url('Lato/Lato-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700; /*bold*/
  src: local('Lato Bold'), local('Lato-Bold'), url('Lato/Lato-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700; /*bold*/
  src: local('Lato BoldItalic'), local('Lato-BoldItalic'), url('Lato/Lato-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900; /*black*/
  src: local('Lato Black'), local('Lato-Black'), url('Lato/Lato-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900; /*black*/
  src: local('Lato BlackItalic'), local('Lato-BlackItalic'), url('Lato/Lato-BlackItalic.ttf') format('truetype');
}

/////////// MAP ///////////
/// https://www.w3.org/TR/2018/REC-css-fonts-3-20180920/
/// all: [ <‘font-style’> ||<‘font-weight’>]? <‘font-size’> [ / <‘line-height’> ]? <‘font-family’> ] | caption | icon | menu | message-box | small-caption | status-bar
/// style: normal, italic, oblique
/// weight: see @font-face positions
/// size: xx-small | x-small | small | medium | large | x-large | xx-large | larger | smaller
/// how this map use with function "font"
/// .element-html {
///   font-family: font(content); // 'Open Sans'
/// }
$fonts: (
name-company-font: 400 1em 'NasalizationRg-Regular',
title-company-font: 700 1.5em/2em 'NasalizationRg-Regular',
section-title: 400 1.5em/1.5em 'NasalizationRg-Regular',
base: 400 15px/1.5em 'Open Sans',
light: 300 'Open Sans',
small: 300 0.7em/1em 'Open Sans',
semi: 600 1em 'Open Sans',
bold: 700 1em 'Open Sans',
extra: 800 1.1em/1.1em 'Open Sans',
link: 600 1em 'Open Sans',
admin-link: 700 15px/23px 'Open Sans',
top-link: 400 18px/40px 'Open Sans',
menu-link: 400 14px/20px 'Open Sans',
menu-default-link: 400 15px/35px 'Lato',
title-page-mobile: 400 1.6rem/1.9rem 'Lato', 
subtitle-page: 400 1.4rem/1.2rem 'Lato',
title-page: 400 4.5vw/5vw 'Lato',
title-1: 700 2.5rem/3rem 'Lato',
title-2: 700 2rem/2.5rem 'Lato',
title-3: 400 1.5rem/2rem 'Lato',
title-4: 400 1.2rem/1.7rem 'Lato',
title-5: 700 1rem/1.5rem 'Lato',
title-6: 700 1em/1.25em 'Lato',
);

/////////////// FUNCTION ///////////////
/// Pobranie fontu z mapy fontów (przykład pod mapą)
@function font($key) {
  @if map-has-key($fonts, $key) {
    @return map-get($fonts, $key);
  }
  @warn "Unknown `#{$key}` in $fonts.";
  @return null;
}
