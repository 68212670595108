@charset 'utf-8';

@import 
'base/girds',     // xs:0, sm:576px (small), md:768px (medium), lg:992px (large), xl:1200px (big) (@include respond-to(big){})
'base/colors',    // color(<key>) | tint(<key>, <percent>) [rozjaśnienie] | shade(<key>, <percent>) [przyciemnienie]
'base/gradients', // gradient(<key>)
'base/shadows';   // shadow(<key>)

@import
'fonts/fonts';

@import
'modules/select',
'modules/finder';

main {

    .container-admin {
        display: none;
    }

    .page-header {
        
        overflow: hidden;
        padding: 0;
        margin: 0;
        display: none;

        .content-header{
            position: relative;
            background-color: rgba(255,255,255,0.01);
            overflow: hidden;
            padding: 0;
            margin: 0;
            height: 256px;
            max-width: 100%;

            .private-background {
                position: absolute;
                z-index: -1;
                left: 0;
                top: 0;
                margin: 0;
                padding: 0;
                right: 0;
                bottom: 0;
                background-color: color(cancel);
            }
			
			&.home-page {
				
				.snow {
					position: absolute;
					z-index: 9;
					left: 0; right: 0; top: 0; bottom: 0;
					background-color: transparent;
					background-image: url(/build/images/snieg-1.png), url(/build/images/snieg-2.png), url(/build/images/snieg-3.png);
					background-size: auto 256px;
					animation: snow 20s linear infinite; 
					opacity: 0.9;

					@keyframes snow {
						0% { background-position:0 0, 0 0, 0 0; }
						25% { background-position: 50px 130px, 80px 100px, -20px 50px; }
						50% { background-position: 125px 260px, 126px 290px, -60px 100px; }
						75% { background-position: 200px 400px, 70px 440px, -30px 206px; }
						100% { background-position: 256px 512px, 0 512px, 0 256px; }
					}
					@-webkit-keyframes ruch {
						0% { background-position:0 0, 0 0, 0 0; }
						25% { background-position: 50px 130px, 80px 100px, -20px 50px; }
						50% { background-position: 125px 260px, 126px 290px, -60px 100px; }
						75% { background-position: 200px 400px, 70px 440px, -30px 206px; }
						100% { background-position: 256px 512px, 0 512px, 0 256px; }
					}
					
					@include respond-to(small){
						background-size: auto 341px;

						@keyframes snow {
							25% { background-position: 50px 180px, 100px 190px, -20px 80px; }
							50% { background-position: 125px 360px, 256px 386px, -60px 170px; }
							75% { background-position: 200px 540px, 470px 560px, -30px 256px; }
							100% { background-position: 341px 682px, 682px 682px, 0 341px; }
						}

						@-webkit-keyframes ruch {
							25% { background-position: 50px 180px, 100px 190px, -20px 80px; }
							50% { background-position: 125px 360px, 256px 386px, -60px 170px; }
							75% { background-position: 200px 540px, 470px 560px, -30px 256px; }
							100% { background-position: 341px 682px, 682px 682px, 0 341px; }
						}
					}
					@include respond-to(medium){
						background-size: auto 440px;

						@keyframes snow {
							25% { background-position: 50px 220px, 100px 220px, -20px 110px; }
							50% { background-position: 0x 440px, 156px 400px, -60px 220px; }
							75% { background-position: -50px 660px, 370px 660px, -30px 330px; }
							100% { background-position: 0 880px, 440px 880px, 0 440px; }
						}
						
						@-webkit-keyframes ruch {
							25% { background-position: 50px 220px, 100px 220px, -20px 110px; }
							50% { background-position: 0x 440px, 156px 400px, -60px 220px; }
							75% { background-position: -50px 660px, 370px 660px, -30px 330px; }
							100% { background-position: 0 880px, 440px 880px, 0 440px; }
						}
					}
					@include respond-to(big){
						background-size: auto 550px;

						@keyframes snow {
							25% { background-position: 50px 100px, 100px 300px, -20px 90px; }
							50% { background-position: 225px 256px, 256px 550px, -60px 210px; }
							75% { background-position: 400px 400px, 470px 850px, -30px 356px; }
							100% { background-position: 550px 550px, 550px 1100px, 0 550px; }
						}
						
						@-webkit-keyframes ruch {
							25% { background-position: 50px 100px, 100px 300px, -20px 90px; }
							50% { background-position: 225px 256px, 256px 550px, -60px 210px; }
							75% { background-position: 400px 400px, 470px 850px, -30px 356px; }
							100% { background-position: 550px 550px, 550px 1100px, 0 550px; }
						}
					}
				}
				
/*				@-webkit-keyframes ruch {
					0% { background-position:0 0, 0 0, 0 0; }
					50% { background-position: 500px 500px, 100px 200px, -100px 150px; }
					100% { background-position: 500px 1000px, 200px 400px, -100px 300px; }
				}*/
			}

            .titleBackVid {
                position: absolute;
                z-index: -1;
                left: 50%;
                top: 0;
                margin: 0;
                padding: 0;
                display: none;

                &#vid {
                    display: block;
                    width: 576px; 
                    height: 256px;
                    transform: translate(-288px);

                    @include respond-to(small) {
                        display: none;
                    }
                }

                &#vid-sm {
                    width: 768px; 
                    height: 341px;
                    transform: translate(-384px);

                    @include respond-to(small) {
                        display: block;
                    }
                    @include respond-to(medium) {
                        display: none;
                    }
                }

                &#vid-md {
                    width: 992px; 
                    height: 440px;
                    transform: translate(-496px);

                    @include respond-to(medium) {
                        display: block;
                    }
                    @include respond-to(large) {
                        display: none;
                    }
                }

                &#vid-lg {
                    width: 1200px; 
                    height: 440px;
                    transform: translate(-600px);
                    @include respond-to(large) {
                        display: block;
                    }
                    @include respond-to(big) {
                        display: none;
                    }
                }

                &#vid-xl {
                    width: 1920px; 
                    height: 550px;
                    transform: translate(-960px);

                    @include respond-to(big) {
                        display: block;
                    }
                }

            }

            /* title */
            .container-title {
                margin-top: 120px;
                height: 60px;

                #page-title {
                    background-color: white;
                    font-size: 7vw;
                    line-height: 9vw;
                    white-space: nowrap;
                    max-width: 100%;
                    overflow: hidden;
                }

                @include respond-to(small) {
                    margin-top: 200px;
                    height: 60px;

                    #page-title {
                        font-size: 44px;
                        line-height: 60px;
                    }
                }

                @include respond-to(medium) {
                    margin-top: 205px;
                    height: 60px;

                    #page-title {
                        font-size: 50px;
                        line-height: 60px;
                    }
                }

                @include respond-to(large) {
                    margin-top: 170px;
                    height: 80px;

                    #page-title {
                        font-size: 60px;
                        line-height: 80px;
                    }
                }

                @include respond-to(big) {
                    margin-top: 220px;
                    height: 100px;

                    #page-title {
                        font-size: 80px;
                        line-height: 100px;
                    }
                }
            }

            h2.subtitle {
                font: font(subtitle-page);
                color: white;
                font-size: 3.5vw;
                line-height: 1.16;
                margin-top: -5px;
                text-align: center;
                display: block;


                @include respond-to(small) {
                    display: block;
                    margin-top: -5px;
                    font-size: 1.2rem;
                }

                @include respond-to(medium) {
                    margin-top: 10px;
                    font-size: 1.3rem;
                }

                @include respond-to(large) {
                    margin-top: 60px;
                    font-size: 1.3rem;
                    
                    div {
                        display: inline;
                    }
                }

                @include respond-to(big) {
                    margin-top: 70px;
                    font-size: 1.6rem;
                }
            }
			
			.vid-buttons {
				margin-top: -20px;
					
				.btn-form-fdf {
					padding-top: 2px;
					padding-bottom: 2px;
				}		
				
				@include respond-to(small) {
					margin-top: -20px;
					
					.btn-form-fdf {
						padding-top: 2px;
						padding-bottom: 2px;
					}				
				}
				
				@include respond-to(medium) {
					margin-top: 20px;
					
					.btn-form-fdf {
						padding-top: 0.375rem;
						padding-bottom: 0.375rem;
					}				
				}
			}

            &.archive-page {
                height: 11vw;

                .container-title {
                    margin-top: 1vw;

                    #page-title{
                        color: color(f-blue);
                    }
                }

                @include respond-to(small) {
                    height: 80px;

                    .container-title {
                        margin-top: 10px;
                    }
                }

                @include respond-to(medium) {
                    height: 90px;

                    .container-title {
                        margin-top: 15px;
                    }
                }

                @include respond-to(large) {
                    height: 110px;

                    .container-title {
                        margin-top: 15px;
                    }
                }

                @include respond-to(big) {
                    height: 140px;

                    .container-title {
                        margin-top: 20px;
                    }
                }
            }


            @include respond-to(small) {
                height: 341px;
            }

            @include respond-to(medium) {
                height: 440px;
            }

            @include respond-to(large) {
                height: 440px;
            }

            @include respond-to(big) {
                height: 550px;
            }
			
			&.adoption{
				height: auto;
				
				@include respond-to(small) {
					height: auto;
				}

				@include respond-to(medium) {
					height: auto;
				}

				@include respond-to(large) {
					height: auto;
				}

				@include respond-to(big) {
					height: auto;
				}
				
				.private-background {
					
					.container {
						
						.row {
							
							.col {
								
								font-size: 1rem;
								color: black;
								line-height: 1.125rem;
							}
						}
					}
				}
			}
        }
    }


    #liveDataLoad {
        

        .row.menu-list {
            z-index: -2;
            margin-top: -35px;
            margin-bottom: 5px;

			#content-sm-menu {
				text-align: center;
				
                .btn.btn-light {
                    padding: 0 10px;
					margin: 2px;
					white-space: nowrap;
                    font: font(menu-default-link);
                    height: 37px;
                    font-size: 0.9rem;
                    line-height: 1.5;
                    color: white;
                    background-color: color(f-green);
                    border-color: color(white-light);
					
					&:hover,
                    &.active {
                        color: white;
                        background-color: shade(f-green, 10);
                    }
				}
			}
			
            #content-menu {

                .btn.btn-light {
                    padding: 0 5px;
                    font: font(menu-default-link);
                    height: 37px;
                    font-size: 0.9rem;
                    line-height: 1.5;
                    color: white;
                    background-color: color(f-green);
                    border-color: color(white-light);
					white-space: nowrap;
                    
                    @include respond-to(medium-small) {
                        padding: 0 10px;
                        font-size: 0.9rem;
                        line-height: 1.5;            
                    }

                    @include respond-to(small) {
                        font-size: 1rem;
                        line-height: 1.5;            
                    }

                    @include respond-to(big){
                        color: color(f-green);
                        background-color: color(white-light);
                        border-color: color(f-green);
                    }

                    &:first-of-type {
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                    }
                    &:last-of-type {
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }

                    &:hover,
                        &.active {
                        color: white;
                        background-color: shade(f-green, 10);
                        
                        @include respond-to(big){
                            background-color: color(f-green);
                        }
                    }
                    
                    
                }

                @include respond-to(big) {
                    align-items: center;
                }
                
            }

            #finder-block {

                #searchForm {

                    .row-search {

                        .input-group {
                            
                            &:first-of-type {
                                padding-right: 5px;
                            }
                            &:last-of-type {
                                padding-left: 5px;
                            }

                            select.custom-select {
                                font: font(menu-default-link);
                                height: 37px;
                                font-size: 0.85rem;
                                line-height: 1.1;
                                border-radius: 5px;
                                border-color: color(f-blue);
                                cursor: context-menu;
                                padding: 5px 7px;
                                /*height: calc(1.5em + 0.75rem + 2px);*/
                                
                                @include respond-to(medium-small){
                                    padding: 0 10px;
                                    font-size: 0.9rem;
                                    line-height: 1.5;
                                }
                                
                                @include respond-to(small){
                                    padding: 0 10px;
                                    font-size: 1rem;
                                    line-height: 1.5;
                                }

                                option {
/*                                  font-size: 0.75rem;
                                    line-height: 1.15;
                                    padding: 5px 7px;*/
                                    color: color(f-blue);
                                    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
                                    
                                    &.empty-value {
                                        padding: 0 7px;
                                    }

                                    &:hover {
                                        color: white;
                                        background-color: color(f-blue);
                                    }
                                }
                            }

                            input.form-control {
                                font: font(menu-default-link);
                                border-top-left-radius: 5px;
                                border-bottom-left-radius: 5px;
                                border-color: color(f-blue);
                                height: 37px;
                                font-size: 0.85rem;
                                line-height: 1.1;

                                @include respond-to(medium-small){
                                    padding: 0 10px;
                                    font-size: 0.9rem;
                                    line-height: 1.5;
                                }
 
                                @include respond-to(small){
                                    padding: 0 10px;
                                    font-size: 1rem;
                                    line-height: 1.5;
                                }
                            }

                            .input-group-append {

                                .input-group-text {
                                    border-color: color(f-blue);
                                    border-top-right-radius: 5px;
                                    border-bottom-right-radius: 5px;
                                    
                                    &.inside-field {
                                        border-top-right-radius: 0;
                                        border-bottom-right-radius: 0;
                                    }
                                    
                                    &.search-btn {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @include respond-to(small) {
                margin-top: -25px;
                margin-bottom: 10px;
            }

            @include respond-to(big) {
                margin-top: -25px;
            }
        }

        hr {
          border-top: 1px solid tint(f-blue, 80);
        }

        h4.group-title {
            color: color(f-blue);
            margin-top: 10px;
            padding-top: 10px;
            font: font(section-title);
        }

        .block-item {
            padding: 10px;

            .card {
                padding: 10px 10px 0 10px;
                border: 1px solid tint(f-blue, 80);
                border-radius: 15px;
                background-color: white;
                box-shadow: shadow(box);
                text-align: center;
                color: shade(f-blue, 30);
                font-size: .9em;
                line-height: 1.2em;

                .item-block-title {
                    font: font(title-4);
                    color: color(title-4);
                    margin: 0 -10px 4px;
                    white-space: nowrap;
                    overflow: hidden;
                }

                .item-block-image {
                    display: block;
                    position: relative;
                    height: 270px;
                    border-bottom: 1px solid tint(f-blue, 80);
                    margin: -10px -10px 15px -10px;
                    padding: 0;
                    background-color: white;
                    border-radius: 15px 15px 0 0;
                    background-position: center;
                    background-size: cover;
					
					.pinned {
						position: absolute;
						right: -10px;
						top: -15px;
						width: auto;
						font-size: 36px;
						line-height: 36px;
						padding: 0;
					}
					
					.collection-label {
						position: absolute;
						bottom: -3px;
						right: -8px;
					}

                    .urgent {
                        position: absolute;
                        top: 0; // 0
                        left: 0;
                        width: 220px; // 250px
                        height: 0; // 30px
                        line-height: 30px;
                        border-left: 30px solid transparent; 
                        border-right: 30px solid transparent; 
                        border-bottom: 30px solid color(f-red-trans-small);
                        text-align: center;
                        text-shadow: -1px 1px 1px rgba(50,0,0,0.8);
                        padding: 0 auto;
                        /*background-color: color(white-light);*/
                        color: white; //color(f-red);
                        font-weight: 700;
                        font-size: 1.35em;
                        text-transform: uppercase;
                        /*transform: rotate(270deg) translateX(-100%);*/
                        transform: translateX(-21px) translateY(135px) rotate(315deg);
                        transform-origin: 0 0;
                    }
					
					.booking {
                        position: absolute;
                        top: 0; // 0
                        left: 0;
                        width: 220px; // 250px
                        height: 0; // 30px
                        line-height: 30px;
                        border-left: 30px solid transparent; 
                        border-right: 30px solid transparent; 
                        border-bottom: 30px solid color(f-blue-trans);
                        text-align: center;
                        text-shadow: -1px 1px 1px rgba(50,0,0,0.8);
                        padding: 0 auto;
                        /*background-color: color(white-light);*/
                        color: white; //color(f-red);
                        font-weight: 700;
                        font-size: 1.35em;
                        text-transform: uppercase;
                        /*transform: rotate(270deg) translateX(-100%);*/
                        transform: translateX(-21px) translateY(135px) rotate(315deg);
                        transform-origin: 0 0;
						
						i {
							color: color(f-yellow);
						}
                    }
/*					.booking {
                        position: absolute;
                        bottom: 0; // 0
                        right: 0;
                        width: 220px; // 250px
                        height: 0; // 30px
                        line-height: 30px;
                        border-left: 30px solid transparent; 
                        border-right: 30px solid transparent; 
                        border-top: 30px solid color(f-gold-trans);
                        text-align: center;
                        text-shadow: -1px 1px 1px rgba(50,0,0,0.8);
                        padding: 0 auto;
                        background-color: color(white-light);
                        color: white; //color(f-red);
                        font-weight: 700;
                        font-size: 1.35em;
                        text-transform: uppercase;
                        transform: rotate(270deg) translateX(-100%);
                        transform: translateX(64px) translateY(30px) rotate(315deg);
                        transform-origin: 0 0;
						
						span {
							position: absolute;
							width: 220px; // 250px
							transform: translateX(-120px) translateY(-31px);
							transform-origin: 0 0;
							text-align: center;
						}
					}*/
                }

                .item-block-city {

                    margin: -15px -10px 10px -10px;
                    padding: 0;

                    .city-name {
                        line-height: 16px;
                        text-align: center;
                        padding: 4px auto;
                        border-radius: 0;
                        background-color: color(white-light);
                        color: shade(f-blue, 40);
                        font-weight: 700;
                        font-size: 0.85em;
                        text-transform: uppercase;
                    }
                }

                .item-block-description {
                    text-align: justify;
                    font-size: 0.85em;
                    line-height: 1.2;
                    color: color(base);
                    height: 50px;
                    overflow: no-display;
                }

                @include respond-to(big){
                    line-height: 1.5;
                }

                .item-block-amount {
                    position: relative;
                    padding: 0 10px;
                    margin: 10px -10px 0;

                    .progress {
                        border-radius: 18px;
                        line-height: 24px;
                        height: 36px;
                        position: relative;
                        text-align: left;

                        .progress-bar {
                            background-color: tint(f-green, 50);
                            transition: width 0.6s ease;
                            padding: 6px 0
                        }

                        .progress-text {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            color: shade(f-green, 50);
                            font-size: 0.9rem;
                            font-weight: 700;
                            padding: 6px 10px;
                            text-align: left;
                            line-height: 24px;

                            span {
                                display: block;
                                float: left;
                                margin: 0;
                                padding: 0;
                                border: none;

                                &.left { 
                                    text-align: left;
                                    width: 40%;
                                }

                                &.center {
                                    text-align: center;
                                    width: 20%;
                                }

                                &.right {
                                    text-align: right;
                                    width: 40%;
                                }
                            }
                        }
                    }
                }

                .item-block-date-end {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    margin-top: 5px;

                    span {
                        font-size: 1.1em;
                        font-weight: 700;
                    }
                }

                .card-footer {
                    margin: 5px -10px 0 -10px;
                    background-color: color(none);
                    border: none;
                    padding: 0;

                    .btn-success {
                        margin-bottom: 10px;
                        min-width: 60%; 
                    }

                    .row {
                        margin:0;
                        padding: 0;

                        > .btn {
                            border-radius: 0;
                            padding: 5px 0;
                            font-size: 0.95rem;

                            &:first-child {
                                border-radius: 0 0 0 15px;
                            }

                            &:last-child {
                                border-radius: 0 0 15px 0;
                            }

                            &.btn-light {
                                cursor: progress;
                            }
                        }
                    }
                }
            }
        }

        .button-next-prev {
            button {
                background-color: transparent;
                border: 0;
                color: color(f-blue);
                font-size: 30px;
                font-weight: bold;
                border-radius: 50%;
                top: -270px;

                @include respond-to(medium){
                    top: -250px;
                }

                &:hover{
                    color: tint(f-blue, 15);
                }

                &.btn-prev {
                    left: -3px;
                }

                &.btn-next {
                    right: -3px;
                }
            }
        }

        .type-button-row {

            .btn.btn-light {
                line-height: 1.15;
                color: white;
                background-color: color(f-blue);
                border-color: shade(f-blue, 10);
                border-radius: 5px;
                padding: 0 10px;
                font: font(menu-default-link);
                height: 37px;
                

                @include respond-to(small) {
                    padding: 0 10px;
                    font: font(menu-default-link);
                    height: 37px;
                }

                &:hover,
                    &.active {
                    color: white;
                    background-color: shade(f-blue, 15);
                }
            }
        }
    }
}

#modalAdd {

    .modal-dialog {

        @include respond-to(small) {
            max-width: 100%;
        }

        @include respond-to(medium) {
            max-width: 90%;
        }

        @include respond-to(large) {
            max-width: 80%;
        }

        @include respond-to(big) {
            max-width: 70%;
        }
    }
}